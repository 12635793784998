<template lang="html">
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Alertas académicas</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>

                            <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            >
                                <v-select
                                v-model="$v.sede.$model"
                                :error-messages="sedeErrors"
                                label="Sede"
                                :items="sedes"
                                item-text="nombre_sede"
                                item-value="id_sede"
                                :loading="loadSede"
                                >
                                </v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            >
                                <v-select
                                v-model="$v.jornada.$model"
                                :error-messages="jornadaErrors"
                                label="Jornada"
                                :items="jornadas"
                                item-text="nombre_jornada"
                                item-value="id_jornada"
                                :loading="loadJornada"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            >
                                <v-select
                                v-model="$v.level.$model"
                                :error-messages="nivelErrors"
                                label="Nivel"
                                :items="levels"
                                item-text="nombre_nivel"
                                item-value="id_nivel"
                                :loading="loadLevel"
                                @change="getGrades"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            >
                                <v-select
                                v-model="$v.grade.$model"
                                :error-messages="gradeErrors"
                                label="Grado"
                                :items="grades"
                                item-text="nombre"
                                item-value="id"
                                :loading="loadGrade"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="1"
                            sm="12"
                            >
                                <v-select
                                label="Sección"
                                :items="sections"
                                item-text="name"
                                item-value="id"
                                v-model="$v.section.$model"
                                :error-messages="sectionErrors"
                                :loading="loadSection"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.bimestre.$model"
                                :error-messages="bimestreErrors"
                                label="Bimestre"
                                :items="bimestres"
                                item-text="name"
                                item-value="id"
                                :loading="loadBimestre"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row
                        justify="center"
                        >
                            <v-col
                            cols="6"
                            sm="6"
                            md="3"
                            >
                                <v-checkbox
                                  v-model="byEmail"
                                  label="Enviar por email"
                                ></v-checkbox>
                            </v-col>
                            <v-col
                            cols="6"
                            sm="6"
                            md="3"
                            >
                                <v-checkbox
                                  v-model="allStudents"
                                  label="Todos los alumnos"
                                  v-show="studentsNoEmail"
                                ></v-checkbox>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            >
                                <v-combobox
                                v-model="$v.student.$model"
                                :error-messages="studentErrors"
                                label="Estudiantes"
                                :items="students"
                                item-text="fullname"
                                item-value="id"
                                required
                                :loading="loadStudents"
                                :disabled="allStudents"
                                v-show="studentsNoEmail"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        <section class="d-flex justify-center mt-4">
                            <div>
                                <v-btn
                                @click="submit"
                                :loading="loadGenerate"
                                v-if="byEmail"
                                >
                                    Confirmar
                                </v-btn>
                            </div>
                            <div>
                                <v-btn
                                @click="submit"
                                v-if="!byEmail"
                                :disabled="allStudents"
                                >
                                    Generar reporte
                                </v-btn>
                            </div>
                            <div>
                                <v-btn
                                class="ml-2"
                                @click="generatePdf"
                                v-show="studentsNoEmail"
                                >
                                    Generar PDF
                                </v-btn>
                            </div>
                        </section>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-3" v-show="studentsByEmail">
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        v-model="selected"
                        :headers="studentsHeaders"
                        :items="students"
                        :search="search"
                        :footer-props="{'items-per-page-options':[-1]}"
                        show-select
                        loading=true
                        :loading-text="loadingText"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                small
                                class="mr-2"
                                @click="showCourses(item)"
                                >
                                    mdi-bookshelf
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            md="12"
            >
                <v-card
                v-show="showResults"
                >
                    <v-tabs
                    background-color="blue accent-4"
                    dark
                    next-icon="mdi-arrow-right-bold-box-outline"
                    prev-icon="mdi-arrow-left-bold-box-outline"
                    show-arrows
                    v-model="tab"
                    >
                        <v-tab
                        v-for="item in tabNames"
                        :key="item.nombre_curso"
                        @click="getData(item)"
                        >
                            {{item.nombre_curso}}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item
                        v-for="item in tabNames"
                        :key="item.nombre_curso"
                        >
                            <v-card flat>
                                <v-card-text>
                                    <v-data-table
                                    :headers="headers"
                                    :items="tabData"
                                    :search="search"
                                    loading=true
                                    :loading-text="loadingText"
                                    >
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
import {mapMutations,mapState} from "vuex"
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],
    data(){
        return {
            tab: null,
            tabNames: [],
            tabData: [],
            headers: [
                {
                    text: 'Nombre actividad',
                    value: 'actividad'
                },
                {
                    text: 'Nota',
                    value: 'finalgrade'
                },
                {
                    text: 'Valor actividad',
                    value: 'rawgrademax'
                },
                {
                    text: 'Comentarios',
                    value: 'feedback'
                }
            ],
            search: '',
            activities: [],
            loadingText: '',
            sedes: [],
            sede: '',
            jornada: '',
            jornadas: [],
            level: '',
            levels: [],
            grade: '',
            grades: [],
            section: '',
            sections: [],
            loadSede: false,
            loadJornada: false,
            loadLevel: false,
            loadGrade: false,
            loadSection: false,
            student: '',
            students: [],
            loadStudents: false,
            allStudents: false,
            byEmail: false,
            studentsByEmail: false,
            studentsNoEmail: false,
            selected: [],
            bimestre: '',
            bimestres: [],
            loadBimestre: false,
            studentsHeaders: [
                {
                    text: 'Carnet',
                    value: 'username'
                },
                {
                    text: 'Nombre completo',
                    value: 'fullname'
                },
                {
                    text: 'Grado',
                    value: 'grado'
                }
            ],
        }
    },
    validations: {
        ciclo: {required},
        sede: {required},
        jornada: {required},
        level: {required},
        grade: {required},
        section: {required},
        student: {required},
        bimestre: {required}
    },
    computed: {
        ...mapState(['schoolCycle']),
        showResults() {
            return this.tabNames.length > 0 ? true : false
        },
        cicloErrors () {
            const errors = []
            if (!this.$v.ciclo.$dirty) return errors
            !this.$v.ciclo.required && errors.push('Elige el ciclo')
            return errors
        },
        sedeErrors () {
            const errors = []
            if (!this.$v.sede.$dirty) return errors
            !this.$v.sede.required && errors.push('Elige la sede')
            return errors
        },
        jornadaErrors () {
            const errors = []
            if (!this.$v.jornada.$dirty) return errors
            !this.$v.jornada.required && errors.push('Elige la jornada')
            return errors
        },
        nivelErrors () {
            const errors = []
            if (!this.$v.level.$dirty) return errors
            !this.$v.level.required && errors.push('Elige el nivel')
            return errors
        },
        gradeErrors () {
            const errors = []
            if (!this.$v.grade.$dirty) return errors
            !this.$v.grade.required && errors.push('Elige el/la grado/carrera')
            return errors
        },
        sectionErrors () {
            const errors = []
            if (!this.$v.section.$dirty) return errors
            !this.$v.section.required && errors.push('Elige sección')
            return errors
        },
        studentErrors () {
            const errors = []
            if (!this.$v.student.$dirty && this.allStudents) return errors
            !this.$v.student.required && !this.allStudents && errors.push('Elige un estudiante')
            return errors
        },
        bimestreErrors () {
            const errors = []
            if (!this.$v.bimestre.$dirty) return errors
            !this.$v.bimestre.required && errors.push('Elige el bimestre')
            return errors
        }
    },
    methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        submit(){
            this.$v.$touch()

            if(!this.$v.sede.$invalid
                && !this.$v.jornada.$invalid
                && !this.$v.level.$invalid
                && !this.$v.grade.$invalid
                && !this.$v.section.$invalid
                && !this.$v.bimestre.$invalid){
                    if(this.byEmail){
                        if(this.selected.length > 0){
                            this.sendByEmail()
                        }else{
                            this.setNotify({'show':true,
                                            'color':'primary',
                                            'message':'Debe seleccionar por lo menos un estudiante',
                                            'time':5000})
                        }
                    }else{
                        if(!this.allStudents && !this.$v.student.$invalid){
                            this.getCoursesActivities()
                        }
                    }
            }
        },
        getCoursesActivities(){
            this.tabNames = []
            this.tabData = []
            this.tabNames = []
            this.tabData = []
            this.setLoading(true)

            this.axios.post('/getcourseactivities',{
                'bimestre': this.bimestre,
                'student': this.student.id,
                'cycle': this.schoolCycle
            })
            .then( (response) => {
                this.setLoading(false)

                this.tabNames = response.data.courses
                this.activities = response.data.activities

                this.getData(this.tabNames[0])
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        getData(item){
            let activities = this.activities.filter((activity) => {
                return activity.id_curso == item.id_curso
            });

            this.tabData = activities
        },
        getDropdownsData(){
            this.loadSede = true
            this.loadJornada = true
            this.loadLevel = true
            this.loadBimestre = true

            this.axios.get('/getdropdownsdata')
            .then( (response) => {
                this.loadSede = false
                this.loadJornada = false
                this.loadLevel = false
                this.loadBimestre = false

                this.sedes = response.data.sedes
                this.jornadas = response.data.jornadas
                this.levels = response.data.niveles
                this.bimestres = response.data.bimestres
            })
            .catch( (error) => {
                this.loadSede = false
                this.loadJornada = false
                this.loadLevel = false
                this.loadBimestre = false
                console.log(error)
            })
        },
        getGrades(){
            this.loadGrade = true

            this.axios.get('/getgrades/'+this.level)
            .then( (response) => {
                this.loadGrade = false

                this.grades = response.data.grados
            })
            .catch( (error) => {
                this.loadGrade = false
                console.log(error)
            })
        },
        getStudents(){
            this.student = ''
            this.students = []
            this.loadStudents = true

            if(this.byEmail){
                this.studentsByEmail = true
            }else{
                this.studentsNoEmail = true
            }

            this.axios.post('/students',{
                sede: this.sede,
                jornada: this.jornada,
                level: this.level,
                grade: this.grade,
                section: this.section,
                cycle: this.schoolCycle
            })
            .then( (response) => {
                this.loadStudents = false
                this.students = response.data.data
                this.selected = response.data.data

                if(this.students.length <= 0){
                    this.setNotify({'show':true,
                                    'color':'primary',
                                    'message':'No se encontró información',
                                    'time':5000})

                    this.loadingText = 'No se encontró información.'
                }
            })
            .catch( (error) => {
                this.loadStudents = false

                console.log(error)
            })
        },
        generatePdf(){
            let student = (this.student != '') ? this.student.id : ''
            let params =    this.sede +"/"+
                            this.jornada +"/"+
                            this.level +"/"+
                            this.grade + "/" +
                            this.section + "/" +
                            this.bimestre + "/" +
                            this.schoolCycle + "/" +
                            student

            var url = process.env.VUE_APP_PDF_URL + "/activities/" + params
            window.open(url,'_blank');
        },
        sendByEmail(){
            this.setLoading(true)

            this.axios.post('/activitiesbyemail',{
                sedeid: this.sede,
                jornadaid: this.jornada,
                levelid: this.level,
                gradeid: this.grade,
                sectionid: this.section,
                students: this.selected,
                bimestre: this.bimestre,
                cycle: this.schoolCycle
            })
            .then( (response) => {
                this.setLoading(false)

                this.setNotify({
                                'show':true,
                                'color':'green',
                                'message':response.data.msg,
                                'time':-1,
                                'showClose':true
                            });
            })
            .catch( (error) => {
                this.setLoading(false)

                console.log(error)
            })
        },
        getSections(){
            if(this.level != '' && this.grade != ''){
                this.loadSection = true

                let grades = this.grades.filter((grade) => {
                    return grade.id == this.grade
                });

                this.sections = grades[0].sections

                this.loadSection = false
            }
        }
    },
    watch: {
        "allStudents": function () {
            this.student = ''
        },
        "level": function(){
            this.grade = ''
            this.section = ''
            this.student = ''
            this.students = []
        },
        "byEmail": function(){
            if(this.students.length > 0){
                if(this.byEmail){
                    this.studentsByEmail = true
                    this.studentsNoEmail = false
                    this.tabNames = []
                    this.tabData = []
                }else{
                    this.studentsNoEmail = true
                    this.studentsByEmail = false
                    this.showResults = true
                }
            }
        },
        "grade": function(){
            if(this.grade != ''){
                this.section = ''
                this.sections = []
                this.student = ''
                this.students = []
                this.getSections()
            }
        },
        "section": function(){
            if(this.section != ''){
                this.student = ''
                this.students = []
                this.getStudents()
            }
        },
        "schoolCycle": function(){
            if(this.sede != '' &&
                this.jornada != '' &&
                this.level != '' &&
                this.grade != '' &&
                this.section != ''){
                this.getStudents()
            }
        }
    },
    created(){
        this.setLayout('main-layout')
        this.getDropdownsData()
    }
}
</script>

<style lang="css" scoped>
</style>
