<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Habilitar Recuperación</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            >
                                <v-select
                                v-model="$v.sede.$model"
                                :error-messages="sedeErrors"
                                label="Sede"
                                :items="sedes"
                                item-text="nombre_sede"
                                item-value="id_sede"
                                :loading="loadSede"
                                >
                                </v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            >
                                <v-select
                                v-model="$v.jornada.$model"
                                :error-messages="jornadaErrors"
                                label="Jornada"
                                :items="jornadas"
                                item-text="nombre_jornada"
                                item-value="id_jornada"
                                :loading="loadJornada"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            >
                                <v-select
                                v-model="$v.level.$model"
                                :error-messages="nivelErrors"
                                label="Nivel"
                                :items="levels"
                                item-text="nombre_nivel"
                                item-value="id_nivel"
                                :loading="loadLevel"
                                @change="getGrades"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            >
                                <v-select
                                v-model="$v.grade.$model"
                                :error-messages="gradeErrors"
                                label="Grado"
                                :items="grades"
                                item-text="nombre"
                                item-value="id"
                                :loading="loadGrade"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="1"
                            sm="12"
                            >
                                <v-select
                                label="Sección"
                                :items="sections"
                                item-text="name"
                                item-value="id"
                                v-model="$v.section.$model"
                                :error-messages="sectionErrors"
                                :loading="loadSection"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <section class="d-flex justify-center mt-4">
                            <div>
                                <v-btn
                                @click="getStudents"
                                >
                                    Consultar
                                </v-btn>
                            </div>
                        </section>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card
                v-show="showStudents"
                >
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="students"
                        :search="search"
                        loading=true
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                small
                                class="mr-2"
                                @click="showCourses(item)"
                                >
                                    mdi-bookshelf
                                </v-icon>
                            </template>

                            <template v-slot:item.actions="{ item }">
                               <v-menu
                               bottom
                               left
                               >
                                   <template v-slot:activator="{ on, attrs }">
                                       <v-btn
                                       icon
                                       v-bind="attrs"
                                       v-on="on"
                                       >
                                           <v-icon>mdi-dots-vertical</v-icon>
                                       </v-btn>
                                   </template>

                                   <v-list>
                                       <v-list-item
                                       @click="addPayment(item)"
                                       >
                                           <v-list-item-title>Agregar pago</v-list-item-title>
                                       </v-list-item>
                                       <v-list-item
                                       @click="showHistory(item)"
                                       >
                                           <v-list-item-title>Historial de pagos</v-list-item-title>
                                       </v-list-item>
                                   </v-list>
                               </v-menu>
                           </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <template>
            <v-row justify="space-around">
                <v-col cols="auto">
                    <v-dialog
                    v-model="dialog"
                    transition="dialog-bottom-transition"
                    max-width="750"
                    >
                        <v-card>
                            <v-toolbar
                            color="primary"
                            dark
                            >
                                Agregar pago recuperación: {{student}}
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-row class="pt-2">
                                        <v-col>
                                            <v-select
                                            label="Curso"
                                            :items="courses"
                                            item-text="coursename"
                                            item-value="id"
                                            v-model="$v.payment.courses.$model"
                                            multiple
                                            :error-messages="courseIdErrors"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-select
                                            label="Banco / Sede"
                                            :items="recoveryPaymentBanks"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="recoveryPaymentBankIdErrors"
                                            v-model="$v.payment.recovery_payment_bank_id.$model"
                                            ></v-select>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                            label="No. Boleta / Recibo"
                                            v-model="$v.payment.payment_number.$model"
                                            :error-messages="paymentNumberErrors"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-menu
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    v-model="$v.payment.payment_date.$model"
                                                    :error-messages="paymentDateErrors"
                                                    label="Fecha de pago"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                v-model="$v.payment.payment_date.$model"
                                                @input="menu = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog = false"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="submit"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </template>

        <template>
            <v-row justify="space-around">
                <v-col cols="auto">
                    <v-dialog
                    v-model="recoveryPaymentsHistory"
                    transition="dialog-bottom-transition"
                    max-width="750"
                    >
                        <v-card>
                            <v-toolbar
                            color="primary"
                            dark
                            >
                                Historial de pagos: {{student}}
                            </v-toolbar>
                            <v-card-text>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    md="4"
                                    >
                                        <v-text-field
                                        v-model="recoveryPaymentSearch"
                                        append-icon="mdi-magnify"
                                        label="Buscar"
                                        hide-details
                                        single-line
                                        >
                                    </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-data-table
                                :headers="recoveryHeaders"
                                :items="recoveryPayments"
                                :search="recoveryPaymentSearch"
                                >
                                    <template v-slot:item.recovery_payment.created_at="{ item }">
                                        <td>{{moment(item.recovery_payment.created_at).format('DD-MM-YYYY')}}</td>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="recoveryPaymentsHistory = false"
                                >
                                    Cancelar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </template>
    </section>
</template>

<script>
import {mapMutations,mapState} from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],
    data() {
        return {
            dateFormatted: '',
            dialog: false,
            sede: '',
            jornada: '',
            level: '',
            grade: '',
            sedes: [],
            jornadas: [],
            levels: [],
            grades: [],
            section: '',
            sections: [],
            loadSede: false,
            loadJornada: false,
            loadLevel: false,
            loadGrade: false,
            loadSection: false,
            students: [],
            search: '',
            headers: [
                {
                    text: 'Carnet',
                    value: 'username'
                },
                {
                    text: 'Nombre completo',
                    value: 'fullname'
                },
                {
                    text: 'Correo electrónico',
                    value: 'email'
                },
                {
                    text: 'Grado',
                    value: 'grado'
                },
                {
                    text: 'Opciones',
                    value: 'actions',
                    sortable: false
                },
            ],
            showStudents: false,
            courses: [],
            payment: {
                recovery_payment_bank_id: null,
                user_id: null,
                courses: null,
                payment_number: '',
                payment_date: '',
                school_cycle: ''
            },
            student: '',
            recoveries: 0,
            recoveryPaymentSearch: '',
            recoveryHeaders: [
                                {
                                    text: 'Curso',
                                    value: 'course.nombre_curso'
                                },
                                {
                                    text: 'Banco',
                                    value: 'recovery_payment.recovery_payment_bank.name'
                                },
                                {
                                    text: 'No. Boleta',
                                    value: 'recovery_payment.payment_number'
                                },
                                {
                                    text: 'Fecha de ingreso',
                                    value: 'recovery_payment.created_at'
                                },
                            ],
            recoveryPayments: [],
            recoveryPaymentBanks: [],
            recoveryPaymentsHistory: false,
            menu: false,
        }
    },
    validations: {
        sede: {required},
        jornada: {required},
        level: {required},
        grade: {required},
        section: {required},
        payment: {
            courses: {required},
            payment_number: {required},
            payment_date: {required},
            recovery_payment_bank_id: {required}
        }
    },
    computed: {
        ...mapState(['schoolCycle']),
        sedeErrors () {
            const errors = []
            if (!this.$v.sede.$dirty) return errors
            !this.$v.sede.required && errors.push('Elige la sede')
            return errors
        },
        jornadaErrors () {
            const errors = []
            if (!this.$v.jornada.$dirty) return errors
            !this.$v.jornada.required && errors.push('Elige la jornada')
            return errors
        },
        nivelErrors () {
            const errors = []
            if (!this.$v.level.$dirty) return errors
            !this.$v.level.required && errors.push('Elige el nivel')
            return errors
        },
        gradeErrors () {
            const errors = []
            if (!this.$v.grade.$dirty) return errors
            !this.$v.grade.required && errors.push('Elige el/la grado/carrera')
            return errors
        },
        sectionErrors () {
            const errors = []
            if (!this.$v.section.$dirty) return errors
            !this.$v.section.required && errors.push('Elige la sección')
            return errors
        },
        paymentNumberErrors () {
            const errors = []
            if (!this.$v.payment.payment_number.$dirty) return errors
            !this.$v.payment.payment_number.required && errors.push('Campo requerido')
            return errors
        },
        courseIdErrors () {
            const errors = []
            if (!this.$v.payment.courses.$dirty) return errors
            !this.$v.payment.courses.required && errors.push('Campo requerido')
            return errors
        },
        paymentDateErrors () {
            const errors = []
            if (!this.$v.payment.payment_date.$dirty) return errors
            !this.$v.payment.payment_date.required && errors.push('Campo requerido')
            return errors
        },
        recoveryPaymentBankIdErrors () {
            const errors = []
            if (!this.$v.payment.recovery_payment_bank_id.$dirty) return errors
            !this.$v.payment.recovery_payment_bank_id.required && errors.push('Campo requerido')
            return errors
        },
    },
    methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        getDropdownsData(){
            this.loadSede = true
            this.loadJornada = true
            this.loadLevel = true
            this.loadBimestre = true

            this.axios.get('/getdropdownsdata')
            .then( (response) => {
                this.loadSede = false
                this.loadJornada = false
                this.loadLevel = false
                this.loadBimestre = false

                this.sedes = response.data.sedes
                this.jornadas = response.data.jornadas
                this.levels = response.data.niveles
                this.bimestres = response.data.bimestres
            })
            .catch( (error) => {
                this.loadSede = false
                this.loadJornada = false
                this.loadLevel = false
                this.loadBimestre = false
                console.log(error)
            })
        },
        getGrades(){
            this.loadGrade = true

            this.axios.get('/getgrades/'+this.level)
            .then( (response) => {
                this.loadGrade = false

                this.grades = response.data.grados
            })
            .catch( (error) => {
                this.loadGrade = false
                console.log(error)
            })
        },
        getRecoveryPaymentBanks() {
            this.axios.get('/recoverypaymentbanks')
            .then( (response) => {
                this.recoveryPaymentBanks = response.data.banks
            })
            .catch( (error) => {
                console.log(error)
            })
        },
        getStudents() {
            this.$v.$touch()

            if(!this.$v.sede.$invalid
                && !this.$v.jornada.$invalid
                && !this.$v.level.$invalid
                && !this.$v.grade.$invalid){
                    this.$v.$reset()
                    this.setLoading(true)

                    this.axios.post('/getrecoverystudents',{
                        'sede': this.sede,
                        'jornada': this.jornada,
                        'level': this.level,
                        'grade': this.grade,
                        'section': this.section,
                        'cycle': this.schoolCycle
                    })
                    .then( (response) => {
                        this.setLoading(false)
                        this.showStudents = false
                        this.students = response.data.students

                        if(this.students.length > 0){
                            this.showStudents = true
                        }else{
                            this.setNotify({'show':true,
                            'color':'primary',
                            'message':'No se encontró información',
                            'time':5000})
                        }
                    })
                    .catch( (error) => {
                        this.showStudents = false
                        this.setLoading(false)
                        console.log(error)
                    })
            }
        },
        addPayment(item) {
            this.student = '[' +item.username+ '] ' +item.fullname
            this.payment.user_id = item.id
            this.courses = item.courses
            this.dialog = true
        },
        showHistory(item) {
            this.getUserRecoveryPayments(item)
            this.student = '[' +item.username+ '] ' +item.fullname
            this.recoveryPaymentsHistory =  true
        },
        getUserRecoveryPayments(item) {
            this.setLoading(true)

            this.axios.get('/userrecoverypayments/'+item.id+'/'+this.schoolCycle)
            .then( (response) => {
                this.recoveryPayments = response.data.recoveryPayments
                this.setLoading(false)
            })
            .catch( (error) => {
                console.log(error)
            })
        },
        submit() {
            this.$v.$touch()

            if(!this.$v.payment.courses.$invalid
                && !this.$v.payment.payment_number.$invalid
                && !this.$v.payment.recovery_payment_bank_id.$invalid
            ){
                this.setLoading(true)

                this.payment.school_cycle = this.schoolCycle

                this.axios.post('/students/pay',this.payment)
                .then( (response) => {
                    this.dialog = false
                    this.setLoading(false)

                    this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})

                    this.getStudents()
                })
                .catch( (error) => {
                    this.setLoading(false)
                    this.handleError(error)
                })
            }
        },
        handleError(error) {
            if(error.status == 422) {
                let errors = error.data.errors

                for (const itemError in errors) {
                    this.setNotify({
                                       'show':true,
                                       'color':'warning',
                                       'message': errors[itemError][0],
                                       'time':5000
                                   })
                }
            }else if(error.status == 400){
                this.setNotify({
                                   'show':true,
                                   'color':'warning',
                                   'message': error.data.message,
                                   'time':5000
                               })
            }
            // else {
            //     alert(error.response.data.message);
            // }
        },
        getSections(){
            if(this.level != '' && this.grade != ''){
                this.loadSection = true

                let grades = this.grades.filter((grade) => {
                    return grade.id == this.grade
                });

                this.sections = grades[0].sections

                this.loadSection = false
            }
        }
    },
    watch: {
        "level": function(){
            this.grade = ''
        },
        "dialog": function () {
            if(!this.dialog){
                this.payment.user_id = null
                this.payment.courses = null
                this.payment.payment_number = ''
                this.payment.payment_date = ''
                this.payment.recovery_payment_bank_id = null
                this.courses = []
                this.$v.$reset()
            }
        },
        "payment.courses": function() {
            let course = this.courses.filter((course) => {
                return this.payment.courses == course.id
            });

            if(course.length > 0) {
                this.recoveries = (course[0].tries != null) ? course[0].tries : 0
            }
        },
        "recoveryPaymentsHistory": function() {
            this.recoveryPayments = []
        },
        "grade": function(){
            if(this.grade != ''){
                this.sections = []
                this.getSections()
            }
        },
        "schoolCycle": function(){
            this.getStudents()
        }
    },
    created(){
        this.setLayout('main-layout')
        this.getDropdownsData()
        this.getRecoveryPaymentBanks()
    }
}
</script>
