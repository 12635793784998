<template>
    <v-row justify="space-around">
        <v-col cols="auto">
            <v-dialog
            v-model="dialog"
            transition="dialog-bottom-transition"
            max-width="750"
            persistent
            >
                <v-card>
                    <v-toolbar
                    color="primary"
                    dark
                    >
                        {{title}}
                    </v-toolbar>
                    <v-card-text>
                        <div class="mx-2">
                            <slot/>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                        color="blue darken-1"
                        text
                        @click="$emit('close')"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                        color="blue darken-1"
                        text
                        @click="$emit('execute')"
                        v-if="isSave"
                        >
                            {{submitBtnName}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            title: {
                type: String
            },
            dialog: {
                type: Boolean
            },
            submitBtn: {
                type: String,
                default() {
                    return ''
                }
            },
            isSave: {
                type: Boolean,
                default() {
                    return true
                }
            }
        },
        computed: {
            submitBtnName() {
                return this.submitBtn == '' ? 'Guardar' : this.submitBtn
            }
        }
    }
</script>
