<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Roles y permisos</h2>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="roles"
                        :search="search"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-menu
                                bottom
                                left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                        @click="initEditRole(item)"
                                        v-can="'roles-edit'"
                                        >
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                        @click="showAlert(item)"
                                        v-can="'roles-delete'"
                                        >
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-fab-transition>
            <v-btn
            color="pink"
            dark
            fixed
            bottom
            right
            fab
            @click="dialog = true"
            v-can="'roles-create'"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>

        <!-- <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                dark
                color="primary"
                >
                    <v-btn
                    icon
                    dark
                    @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Nuevo rol</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        dark
                        text
                        @click="submit"
                        >
                            Guardar
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-form>
                                <v-text-field
                                label="Nombre"
                                v-model="$v.role.name.$model"
                                :error-messages="nameErrors"
                                ></v-text-field>
                            </v-form>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col
                        cols="12"
                        >
                            <v-card>
                                <v-card-text>
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-text-field
                                            v-model="permSearch"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            hide-details
                                            single-line
                                            >
                                        </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-data-table
                                    v-model="role.permissions"
                                    :headers="permHeaders"
                                    :items="permissions"
                                    :search="permSearch"
                                    :footer-props="{'items-per-page-options':[-1]}"
                                    show-select
                                    >
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog> -->

        <Modal
        :dialog="dialog"
        :title="dialogTitle"
        @close="dialog = false"
        @execute="submit"
        >
            <template v-slot>
                <v-row>
                    <v-col>
                        <v-form>
                            <v-text-field
                            label="Nombre"
                            v-model="$v.role.name.$model"
                            :error-messages="nameErrors"
                            ></v-text-field>
                        </v-form>
                    </v-col>
                </v-row>
                <v-row class="mt-3">
                    <v-col
                    cols="12"
                    >
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    md="4"
                                    >
                                        <v-text-field
                                        v-model="permSearch"
                                        append-icon="mdi-magnify"
                                        label="Search"
                                        hide-details
                                        single-line
                                        >
                                    </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-data-table
                                v-model="role.permissions"
                                :headers="permHeaders"
                                :items="permissions"
                                :search="permSearch"
                                :footer-props="{'items-per-page-options':[-1]}"
                                show-select
                                >
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </Modal>

        <QuestionAlert
        :alert="onAlert"
        question="¿Esta seguro de eliminar el rol?"
        @close="onAlert = false"
        @execute="deleteItem"
        />
    </section>
</template>

<script>
    import {mapMutations} from "vuex"
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'
    // import DataTable from '@/components/Datatable.vue'
    import QuestionAlert from '@/components/QuestionAlert.vue'
    import Modal from '@/components/Modal.vue'

    export default {
        mixins: [validationMixin],
        data() {
            return {
                dialog: false,
                headers: [
                    {
                        text: 'Nombre',
                        value: 'name',
                    },
                    {
                        text: 'Actions',
                        value: 'actions',
                        width: '5%',
                        sortable: false
                    },
                ],
                roles: [],
                search: '',
                role: {
                    id: null,
                    name: '',
                    permissions: [],
                },
                permissions: [],
                permSearch: '',
                permHeaders: [
                    {
                        text: 'Nombre',
                        value: 'name'
                    },
                    {
                        text: 'Descripción',
                        value: 'description'
                    },
                ],
                onAlert: false,
                itemToDelete: null
            }
        },
        validations: {
            role: {
                    name: {required}
             }
        },
        computed: {
            dialogTitle() {
                return (this.role.id != null) ? "Editar rol" : "Nuevo rol"
            },
            nameErrors () {
                const errors = []
                if (!this.$v.role.name.$dirty) return errors
                !this.$v.role.name.required && errors.push('Campo requerido')
                return errors
            },
        },
        methods: {
            ...mapMutations(['setLayout','setNotify','setLoading']),
            get() {
                this.setLoading(true)

                this.axios.get('/roles')
                .then( (response) => {
                    this.setLoading(false)

                    this.roles = response.data.roles
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            },
            submit() {
                this.$v.$touch()

                if(!this.$v.$invalid){
                    if(this.role.id == null){
                        this.save()
                    }else{
                        this.update()
                    }
                }
            },
            save() {
                this.setLoading(true)

                this.axios.post('/roles',this.role)
                .then( (response) => {
                    this.role.name = ''
                    this.role.permissions = []
                    this.setLoading(false)
                    this.get()
                    this.dialog = false

                    this.setNotify({'show':true,
									'color':'success',
									'message':response.data.message,
									'time':5000})
                })
                .catch( (error) => {
                    this.dialog = false
                    this.setLoading(false)
                    console.log(error)
                })
            },
            initEditRole(item){
                this.role.id = item.id
                this.role.name = item.name
                this.role.permissions = item.permissions
                this.dialog = true
            },
            getPermissions() {
                this.setLoading(true)

                this.axios.get('/permissions')
                .then( (response) => {
                    this.setLoading(false)

                    this.permissions = response.data.permissions
                })
                .catch( (error) => {
                    // this.loadGrade = false
                    this.setLoading(false)
                    console.log(error)
                })
            },
            showAlert(item) {
                this.itemToDelete = item
                this.onAlert = true
            },
            deleteItem() {
                this.onAlert = false
                this.setLoading(true)

                this.axios.delete('/roles/'+this.itemToDelete.id)
                .then( (response) => {
                    this.itemToDelete = null
                    this.setLoading(false)
                    this.setNotify({'show':true,
									'color':'success',
									'message':response.data.message,
									'time':5000})
                    this.get()
                })
                .catch( (error) => {
                    this.dialog = false
                    this.setLoading(false)
                    console.log(error)
                })
            },
            update(){
                this.setLoading(true)

                this.axios.put('/roles/'+this.role.id,this.role)
                .then( (response) => {
                    this.role.id = null
                    this.role.name = ''
                    this.role.permissions = []
                    this.setLoading(false)

                    this.setNotify({'show':true,
									'color':'success',
									'message':response.data.message,
									'time':5000})

                    this.get()
                    this.dialog = false
                })
                .catch( (error) => {
                    this.dialog = false
                    this.setLoading(false)
                    console.log(error)
                })
            },
            closeDialog() {
                this.dialog = false
                this.selected = []
            }
        },
        created(){
            this.setLayout('main-layout')
        },
        mounted(){
            this.get()
            this.getPermissions()
        },
        components: {
            // DataTable
            QuestionAlert,
            Modal
        },
        watch: {
            "dialog": function () {
                if(!this.dialog){
                    this.role.id = null
                    this.role.name = ''
                    this.role.permissions = []
                    this.$v.$reset()
                }

            },
        }
    }
</script>
